<template lang="pug">
    v-card#lesson-title.title-area.text-center.text-md-left(v-if="lesson && lesson.show", tile flat color="primary" dark).pa-5.mt-n1.mt-md-n0
          v-row(align="center" justify="center")
            v-col(cols="12" md="4")
              router-link.title.font-weight-bold.accent--text.ml-md-3.text-decoration-none(:to="`/lessons/${lesson.show.toLowerCase().split(' ').join('_')}`")
                v-icon.mr-2(color="accent") mdi-arrow-left
                | {{lesson.show}}
            v-col(cols="12" md="4").text-center
              .title.episode.oblique.ml-2  Season {{lesson.season}}, Episode {{lesson.episode}}
            v-col(cols="12" md="4").text-md-right
              .title.oblique.ml-2.mr-md-3 "{{lesson.title}}"
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "Title",
  computed: {
    ...get({ lesson: "Lessons/lesson" }),
  },
};
</script>
