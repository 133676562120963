<template lang="pug">
    .lesson-section.mb-5(:id="anchor ? anchor : null")
        v-card.intro.pa-5(outlined, :class="color")
          v-row(justify="space-between" align="center" dense)
            v-col(cols="12" :md="time ? 8 : 12", v-if="title")
              h2.mb-0.text-uppercase(:class="{'text-center': !subtitle && !time}") {{title}}
            v-col(cols="12" md="4", v-if="time").text-md-right
              .time-area
                span.oblique.time {{time}}
                v-icon.ml-1(color="grey lighten-2") mdi-clock-outline
          v-divider.my-3.grey.lighten-4(v-if="title || subtitle || time")
          
          p.mb-4(v-if="subtitle")
            b {{subtitle}}
          p.mb-4(v-if="note") {{note}}
          .access-control(:class="{'fade-out': $route.params.requiresSubscription && (!userData || !userData.subscriptionIsActive)}")
            slot
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "Section",
  props: {
    anchor: { type: String, default: null },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    note: { type: String, default: null },
    time: { type: String, default: null },
    color: { type: String, default: null },
  },
  computed: { ...get({ userData: "User/userData" }) },
};
</script>

<style lang="scss" scoped>
.fade-out {
  position: relative;
}
.fade-out:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), #fff);
}
</style>
