<template lang="pug">
    .key-expression-type
        v-tooltip(top, v-for="type in sortedTypes", :key="expression + type", v-if="lookup[type]" :color="type && lookup[type] ? lookup[type].color : null" max-width="300px").mx-auto
            template(v-slot:activator="{ on, attrs }")
                v-chip(v-on="on", :color="lookup[type].color").ma-2 {{lookup[type].title}}
            span.primary--text {{lookup[type].description}}
        
</template>

<script>
import sortBy from "lodash.sortby";
export default {
  name: "KeyExpressionType",
  props: {
    expression: { type: String, required: true },
    types: { type: Array, required: true },
  },
  data() {
    return {
      lookup: {
        slang: {
          title: "Slang",
          description:
            "Slang expressions can be friendly or rude. Avoid using slang in professional communication, unless you have a casual relationship.",
          color: "yellow lighten-4",
        },
        neutral: {
          title: "Neutral",
          description: "Neutral expressions can be used in most situations.",
          color: "grey lighten-3",
        },
        abbreviation: {
          title: "Abbrev",
          description:
            "Abbreviations are short versions of longer words or longer fixed expressions",
          color: "purple lighten-5",
        },
        acronym: {
          title: "Acronym",
          description:
            "Acronyms and initialisms are letters instead of words, like FBI, DNA, SCUBA, AIDS, etc",
          color: "green lighten-4",
        },
        idiom: {
          title: "Idiom",
          description:
            "Idioms are fixed expressions that native speakers use. Sometimes the meaning of the idiom is hard to guess from the words.",
          color: "blue lighten-4",
        },
        rude: {
          title: "Rude",
          description: "Rude or forceful expressions must be used with care",
          color: "orange lighten-3",
        },
        exclamation: {
          title: "Exclamation",
          description: "A sudden cry or remark of surprise",
          color: "gold lighten-3",
        },
      },
    };
  },
  computed: {
    sortedTypes() {
      if (!this.types || this.types.length < 1) return this.types;
      return [...new Set(sortBy(this.types))];
    },
  },
};
</script>
