<template lang="pug">
  #discussion
    Section(title="Discussion", :subtitle="subtitle", :time="time")
      ShadedQuestions(:questions="lesson.discussion")
</template>

<script>
import { get } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";
import ShadedQuestions from "@/components/Lesson/ShadedQuestions.vue";
export default {
  name: "Discussion",
  components: { Section, ShadedQuestions },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
  },
  computed: {
    ...get({ lesson: "Lessons/lesson" }),
  },
};
</script>
