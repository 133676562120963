<template lang="pug">
  #keyExpressions
    Section(title="Key Expressions", :subtitle="subtitle", :time="time")
      v-card(v-if="isTeacher", flat color="grey lighten-5").pa-5.mb-6.text--secondary
          b.mb-3 How to use this section
          p
            b.mr-2 Level 1:
            | For each expression, review the meaning and example with your student, then ask your student to listen/repeat the example sentence after you.
          p
            b.mr-2 Level 2:
            | You can ask your student to create their own example sentences, using the same expression.

      v-row(dense)
        v-col(cols="12" md="6" v-for="list, listIdx in expressions", :key="'list-'+listIdx")
          v-expansion-panels(flat :popout="$vuetify.breakpoint.mdAndUp")
            v-expansion-panel(v-for="itm, idx in list", :key="itm.expression")
              v-expansion-panel-header(color="grey lighten-4")
                .text-md-h7.font-weight-bold {{itm.expression}}

              v-expansion-panel-content(color="grey lighten-5")
                template(v-if="itm.meaning")
                  v-subheader Meaning
                  .text.ml-6 {{itm.meaning}}
                
                template(v-if="itm.example")
                  v-subheader.mt-3 Example
                  .text.ml-6 {{itm.example}}
                
                template(v-if="itm.notes")
                  v-subheader.mt-3 Notes
                  .text.ml-6 {{itm.notes}}
                
                template(v-if="itm.types && itm.types.length > 0")
                  v-subheader.mt-3 Type
                  KeyExpressionType(:expression="itm.expression", :types="itm.types").ml-4
                
                v-subheader.mt-3(:title="'Expression ' + (itm.id + 1)") Pronunciation
                .ml-4
                  .d-flex(v-if="!preview")
                    Audio(:filename="`${itm.id}_expression.mp3`", :key="itm.id").mt-md-2.ml-4.ml-md-2
                  em(v-else) This is a preview, but when we publish this lesson we'll also generate pronunciation audio for this expression.


</template>

<script>
import { get } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";
import Audio from "@/components/Audio.vue";

import KeyExpressionType from "@/components/Lesson/Sections/KeyExpressions/KeyExpressionType.vue";
import { chunkArray } from "@/helpers.js";
export default {
  name: "KeyExpressions",
  components: { Section, Audio, KeyExpressionType },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
    preview: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    ...get({ isTeacher: "Lessons/isTeacher", lesson: "Lessons/lesson" }),
    expressions() {
      const expressions = this.lesson.keyExpressions.map((expression, id) => {
        return { ...expression, id };
      });
      return chunkArray(expressions, 2);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.key-expressions div,
.key-expressions p {
  font-size: 1rem;
  .expression {
    font-size: 1.75rem;
    line-height: 1.25;
    font-weight: bold;
  }
}

.blurry {
  filter: blur(10px);
}

.hover-btn {
  position: absolute;
  cursor: pointer;
}
</style>
