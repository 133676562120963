<template lang="pug">
  .shaded-questions
    v-card(v-for="q, idx in questions", :key="q", :color="'grey lighten-' + (idx + 2 < 5 ? idx+2 : 5)" flat).mb-5.pa-5
      v-row(align="center")
        v-col(cols="12" md="1").text-center
          v-chip(color="grey lighten-5").px-2 Q {{idx+1}}
        v-col(cols="12" md="11")
          .question.title {{q}}
</template>

<script>
export default {
  name: "ShadedQuestions",
  components: {},
  props: {
    questions: { type: Array, default: null },
  },
  computed: {},
};
</script>
