<template lang="pug">
  #warmUp(v-if="lesson && lesson.warmup && lesson.warmup.length > 0")
    Section(title="Warm-Up", :subtitle="subtitle", :time="time")
      v-row.flex-column-reverse.flex-md-row
        v-col(cols="12" :md="isTeacher ? 8 : 12")
          ShadedQuestions(:questions="lesson.warmup")
        
        v-col(v-if="isTeacher" cols="12" md="4")
          .px-12
            v-img(src="@/assets/images/warmup.svg" cover)
          
</template>

<script>
import { get } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";
import ShadedQuestions from "@/components/Lesson/ShadedQuestions.vue";
export default {
  name: "WarmUp",
  components: { Section, ShadedQuestions },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
  },
  computed: {
    ...get({ isTeacher: "Lessons/isTeacher", lesson: "Lessons/lesson" }),
  },
};
</script>
