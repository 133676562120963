<template lang="pug">
  #summary
    Section(:title="title", :subtitle="subtitle")
        v-row(v-if="lesson && lesson.show")
          v-col(cols="12" md="5")
            a(href="#" @click.prevent)
              template(v-if="posterImageIsReady")
                  v-img(:src="getLessonImage()", lazy-src="@/assets/summary_image_loader.jpg", @click="posterImageIsReady = !posterImageIsReady")
              template(v-else)
                  v-img(:src="posterImage", @click="posterImageIsReady = !posterImageIsReady")
          v-col(cols="12" md="7")
            template(v-if="isTeacher")
              p.text-left(v-for="line, idx in summaryText", :key="line") {{line}}
                span.ml-2(v-if="idx === summaryText.length -1 && !showAll && lesson.summary.text.join('').length > maxLength") [...]
              template(v-if="!showAll && lesson.summary.text.join('').length > maxLength")
                v-btn(color="primary" outlined @click="showAll = true") Show all
                  v-icon.ml-2 mdi-chevron-down

            template(v-else)
              v-expansion-panels(flat)
                v-expansion-panel
                  v-expansion-panel-header 
                    h4 {{panelHeader}}
                  v-expansion-panel-content
                    p.text-left(v-for="line, idx in lesson.summary.text", :key="line") {{line}}
                    a(:href="lesson.summary.source", target="_blank")
                        em [Source]
</template>

<script>
import { get } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";
export default {
  name: "Summary",
  components: { Section },
  props: {
    subtitle: { type: String, default: null },
    panelHeader: {
      type: String,
      default: "Expand to see the plot summary of this episode",
    },
    time: { type: String, default: null },
  },
  data() {
    return {
      posterImageIsReady: false,
      showAll: false,
      maxLength: 250,
    };
  },
  mounted() {
    this.initPoster();
  },
  computed: {
    ...get({ isTeacher: "Lessons/isTeacher", lesson: "Lessons/lesson" }),
    title() {
      return `${this.lesson.show} (S${this.lesson.season}, E${this.lesson.episode}) Plot Summary`;
    },
    summaryText() {
      return this.showAll
        ? this.lesson.summary.text
        : this.lesson.summary.text
            .join("||")
            .substr(0, this.maxLength)
            .split("||");
    },
    posterImage() {
      return this.lesson && this.lesson.show
        ? `/posters/${this.lesson.show.split(" ").join("_").toLowerCase()}.jpg`
        : null;
    },
  },
  methods: {
    initPoster() {
      const lessonImage = this.getLessonImage();

      const img = new Image();
      img.src = lessonImage;
      // document.getElementById("summary").appendChild(img);
      const vm = this;
      img.onload = function () {
        console.log("image was preloaded");
        vm.posterImageIsReady = true;
      };
    },
    getLessonImage() {
      if (this.lesson.imagePrompts && this.lesson.imagePrompts.length > 0) {
        const animatedImage = this.lesson.imagePrompts.find(
          (itm) => itm.isAnimated
        );

        const show = this.lesson.show.toLowerCase().split(" ").join("_");
        const season = ("" + this.lesson.season).padStart(2, "0");

        const episode = ("" + this.lesson.episode).padStart(2, "0");

        const image = this.lesson.imagePrompts[0].image;
        return animatedImage
          ? animatedImage.image
          : `/screencaps/shows/${show}/s${season}_e${episode}/${image}`;
      } else {
        return this.posterImage();
      }
    },
  },
};
</script>
