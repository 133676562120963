<template lang="pug">
  #checkUnderstanding
    Section(title="Check Understanding", :subtitle="subtitle", :time="time")
        v-card(v-if="isTeacher", flat color="grey lighten-4").pa-5.text--secondary.mb-4
          h4.mb-3 How to use this section
          p.mb-0 As the teacher, you can press the "SEE ANSWER" button to check our suggested answers. Students do not see this button.

        v-row.flex-column-reverse.flex-md-row
            v-col(cols="12" md="6")
                ol
                    li(v-for="q, idx in lesson.checkUnderstanding", :key="q.question")
                        .question.d-flex
                            .text(:class="{'font-weight-bold': checkUnderstandingAnswers.includes(idx), 'accent--text': checkUnderstandingAnswers.includes(idx)}") {{q.question}} 
                            .icon(v-if="checkUnderstandingAnswers.includes(idx)")
                                v-icon(color="accent").ml-1 mdi-check-circle
                        v-expand-transition
                            .answer(v-if="checkUnderstandingAnswers.includes(idx)")
                                b.mr-1 Answer:
                                span {{q.answer}}
                        v-btn(v-if="isTeacher && !checkUnderstandingAnswers.includes(idx)" small text @click="checkUnderstandingAnswers.push(idx)") See answer

            v-col(cols="12" md="6")
                .px-12
                    v-img(src="@/assets/images/checkUnderstanding.svg" cover)
</template>

<script>
import { get } from "vuex-pathify";

import Section from "@/components/Lesson/Section.vue";
export default {
  name: "CheckUnderstanding",
  components: { Section },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
  },
  data() {
    return {
      checkUnderstandingAnswers: [],
    };
  },
  computed: {
    ...get({ isTeacher: "Lessons/isTeacher", lesson: "Lessons/lesson" }),
  },
};
</script>
