<template lang="pug">
.audio.d-flex.justify-center.align-center.flex-column
  v-btn(icon, small, @click="doPlay", outlined, :color="colorClass" :disabled="audioIsPlaying")
    v-icon {{icon}}
</template>

<script>
import { get, call } from "vuex-pathify";

export default {
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
  },
  props: {
    filename: { type: String, required: true },
    color: { type: String, default: "primary" },
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    ...get({
      audioIsPlaying: "Audio/audioIsPlaying",
      activeSrc: "Audio/activeSrc",
    }),
    colorClass() {
      return this.error ? "warning" : this.color + "--text";
    },
    icon() {
      if (this.error) return "mdi-volume-off";

      if (
        this.activeSrc &&
        this.activeSrc.toLowerCase().includes(this.filename.toLowerCase())
      ) {
        return this.audioIsPlaying ? "mdi-pause" : "mdi-play";
      } else {
        return this.audioIsPlaying ? null : "mdi-play";
      }
    },
  },
  methods: {
    ...call({
      playAudio: "Audio/playAudio",
      getAudioFromStorage: "Lessons/getAudioFromStorage",
    }),
    async doPlay() {
      const downloadPath = await this.getAudioFromStorage({
        type: "keyExpressions",
        filename: this.filename,
      });

      if (downloadPath && downloadPath.length > 0)
        await this.playAudio(downloadPath);
      else {
        this.error = true;

        setTimeout(() => {
          this.error = false;
        }, 1500);
      }
    },
  },
};
</script>
