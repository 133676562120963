<template lang="pug">
    Section(title="Sections")
        v-navigation-drawer(floating permanent)
            v-list(:dense="dense")
                v-list-item-group
                    v-list-item(v-for="section, idx in items", :key="section", @click="navToSectionTitle({to: section})")
                        v-list-item-title.font-weight-bold {{idx+1}} - {{section}}
</template>

<script>
import { get, call } from "vuex-pathify";

import Section from "@/components/Lesson/Section.vue";

export default {
  name: "LessonNavDrawer",
  components: { Section },
  props: { dense: { type: Boolean, default: false } },
  watch: {
    $route(newVal) {
      if (this.$route.hash)
        this.navToSectionTitle({ to: newVal.hash.split("#").join("") });
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.$route.hash)
      this.navToSectionTitle({ to: this.$route.hash.split("#").join("") });
  },
  computed: {
    ...get({
      lesson: "Lessons/lesson",
      isTeacher: "Lessons/isTeacher",
      sections: "Nav/sections",
    }),
    items() {
      const sections = this.sections;

      let items = [];
      if (this.isTeacher) {
        items = [
          sections.warmUp,
          sections.keyExpressions,
          sections.imagePrompts,
          sections.checkUnderstanding,
          sections.discussion,
          sections.shadowing,
          sections.rolePlay,
          sections.cloze,
          sections.tensePractice,
          sections.articlesPractice,
          sections.hangman,
        ];
      } else {
        items = [
          sections.warmUp,
          sections.keyExpressions,
          sections.imagePrompts,
          sections.checkUnderstanding,
          sections.hangman,
          sections.matching,
          sections.dictation,
          sections.cloze,
          sections.shadowing,
          sections.discussion,
          sections.tensePractice,
          sections.articlesPractice,
          sections.summary,
        ];
      }

      if (!this.lesson || !this.lesson.cloze || this.lesson.cloze.length < 1)
        items = items.filter((itm) => itm !== sections.cloze);

      if (
        !this.lesson ||
        !this.lesson.tensePractice ||
        !this.lesson.tensePractice.past ||
        this.lesson.tensePractice.past.length < 1
      )
        items = items.filter((itm) => itm !== sections.tensePractice);

      if (
        !this.lesson ||
        !this.lesson.imagePrompts ||
        this.lesson.imagePrompts.length < 1
      )
        items = items.filter((itm) => itm !== sections.imagePrompts);

      return items;
    },
  },
  methods: {
    ...call({ navToSectionTitle: "Nav/navToSectionTitle" }),
  },
};
</script>
