<template lang="pug">
    Section(title="Getting Started", subtitle="Welcome your students to the lesson and ask them what they thought of today's episode.", time="2 ~ 4 minutes" color="secondary lighten-1")
        ol
            li What did you think of this episode of {{lesson.show}}?
            li Can you give {{lesson.show}} season {{lesson.season}}, ep. {{lesson.episode}} a star-rating between 1 and 5 stars?
            li What factors did you consider when giving your rating?
            li Are there any particular scenes, characters, or details you're looking forward to discussing?
</template>

<script>
import Section from "@/components/Lesson/Section.vue";
export default {
  name: "GettingStarted",
  components: { Section },
  props: { lesson: { type: Object, required: true } },
};
</script>
